/* Contenedor principal */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    height: 100vh;
  }
  
  /* Título */
  .home-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  /* Contenedor de las cartas */
  .card-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
  
  /* Carta individual */
  .card {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .card h2 {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .card p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Hover y efectos */
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
  }
  