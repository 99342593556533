/* Contenedor principal */
.add-car-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-car-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  /* Estilo de mensajes */
  .message {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: green;
  }
  
  .message.error {
    color: red;
  }
  
  /* Formulario */
  .add-car-form .form-group {
    margin-bottom: 1rem;
  }
  
  .add-car-form label {
    display: block;
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 0.5rem;
  }
  
  .add-car-form input,
  .add-car-form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
    outline: none;
  }
  
  .add-car-form textarea {
    resize: vertical;
    height: 100px;
  }
  
  .add-car-form input[type="file"] {
    border: none;
  }
  
  .add-car-form input:focus,
  .add-car-form textarea:focus {
    border-color: #007bff;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  