/* Contenedor principal */
.edit-car-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .edit-car-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .message {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: green;
  }
  
  .message.error {
    color: red;
  }
  
  /* Lista de coches */
  .car-list ul {
    list-style: none;
    padding: 0;
  }
  
  .car-list li {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .car-list li:hover {
    background-color: #f0f0f0;
  }
  
  .car-list li.selected {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Formulario de edición */
  .edit-car-form .form-group {
    margin-bottom: 1rem;
  }
  
  .edit-car-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  
  .edit-car-form input,
  .edit-car-form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  .current-images,
  .new-images {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .image-item {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .image-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color 0.3s;
  }
  
  .image-item button:hover {
    background-color: rgba(255, 0, 0, 1);
  }
  
  .remove-selected {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .new-images .image-item {
    border: 2px dashed #007bff;
  }
    