/* Contenedor principal */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  /* Tarjeta de inicio de sesión */
  .login-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Título */
  .login-card h1 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #333333;
  }
  
  /* Grupo de formulario */
  .form-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333333;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus {
    border-color: #007bff;
  }
  
  /* Botón */
  .login-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .login-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Mensaje de error */
  .error-message {
    margin-top: 1rem;
    color: #ff4d4d;
    font-size: 0.9rem;
  }
  