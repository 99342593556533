.mark-as-sold-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .car-list {
    margin-bottom: 20px;
  }
  
  .car-list ul {
    list-style: none;
    padding: 0;
  }
  
  .car-list li {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .car-list li:hover {
    background-color: #f9f9f9;
  }
  
  .car-list .selected {
    background-color: #cce5ff;
    border-color: #007bff;
  }
  
  .sold-tag {
    color: red;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .mark-as-sold-form {
    margin-top: 20px;
  }
  
  .mark-as-sold-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .mark-as-sold-button:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: green;
  }
  