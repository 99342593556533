/* Contenedor principal */
.show-car-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Encabezado principal */
.show-car-container h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

/* Mensaje */
.message {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: green;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 10px;
    border-radius: 5px;
}

/* Lista de coches */
.car-list {
    margin-bottom: 20px;
}

.car-list h2 {
    font-size: 20px;
    color: #555;
    margin-bottom: 10px;
}

.car-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.car-list li {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.car-list li:hover {
    background-color: #f9f9f9;
}

.car-list .selected {
    background-color: #cce5ff;
    border-color: #007bff;
}

.car-list .sold-tag {
    color: red;
    font-weight: bold;
    margin-left: 10px;
    font-size: 0.9em;
}

/* Formulario para marcar como disponible */
.show-car-form {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.show-car-form h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

/* Botón para volver a poner a la venta */
.mark-as-available-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.mark-as-available-button:hover {
    background-color: #218838;
}
